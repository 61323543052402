import lottie from 'lottie-web';

let animationContainers = document.getElementsByClassName('lottie-animation');
animationContainers = Array.from(animationContainers);

const fadeInBody = () => {
  document.body.classList.add('loaded');
}

$(document).ready(() => {
  animationContainers.map(container => {
      const animation = lottie.loadAnimation({
          container: container,
          renderer: 'svg',
          loop: container.dataset.loop ? true : false,
          autoplay: true,
          path: `js/animations/${container.dataset.animation}`
        });

      animation.addEventListener('data_ready', fadeInBody);
  });

});