$(document).ready(() => {
    const sliderContainer = $('.slider-container');
    const nextSlide = $('.mc-slider-next');
    const prevSlide = $('.mc-slider-prev');
    const controls = $('.slider-controls__control');
    const controlsStart = $('.slider-controls__start');
    const controlsNext = $('.slider-controls__control--next');
    const controlsPrev = $('.slider-controls__control--prev');
    const sliderItems = $('.slider__item');
    let slider;

    if(sliderContainer.length > 0) {
        slider = sliderContainer.bxSlider({
            touchEnabled: false,
            keyboardEnabled: true,
            onSlideBefore: (el, oldIndex, newIndex) => toggleControlsVisibility(newIndex)
        });

        nextSlide.click(function() {
            slider.goToNextSlide()
        })

        prevSlide.click(() => {
            slider.goToPrevSlide();
        })

        const toggleControlsVisibility = (slide) => {
            const sliderHiddenClass = 'slider-controls__control--hidden';
            const startHiddenClass = 'slider-controls__start--hidden';
            //console.log(sliderItems.length);

            switch(slide) {
                case 0: // if first slide hide both
                    controls.addClass(sliderHiddenClass);
                    controlsStart.removeClass(startHiddenClass);
                    break;
                case sliderItems.length - 1: // if last slide hide next show prev
                    controlsStart.addClass(startHiddenClass);
                    controlsNext.addClass(sliderHiddenClass);
                    controlsPrev.removeClass(sliderHiddenClass);
                    break;
                default: // else show both
                    controls.removeClass(sliderHiddenClass);
                    controlsStart.addClass(startHiddenClass);
            }
        }
    }
});
